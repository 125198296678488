import React, { useState } from 'react';
import axios from 'axios';

const AddGuest = () => {
  const [name, setName] = useState('');
  const [age, setAge] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    const guestData = { name, age: parseInt(age, 10) };
    console.log('Sending guest data:', guestData);
    try {
      const response = await axios.post('https://fraternity-guestlist-api-c75d55c7a81a.herokuapp.com/guests', guestData);
      console.log('Response:', response.data);
      // Handle success (e.g., clear form, show success message)
    } catch (error) {
      console.error('Error adding guest:', error.response?.data || error.message);
      // Handle error (e.g., show error message to user)
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Name"
        required
      />
      <input
        type="number"
        value={age}
        onChange={(e) => setAge(e.target.value)}
        placeholder="Age"
        required
      />
      <button type="submit">Add Guest</button>
    </form>
  );
};

export default AddGuest;
