import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Header from './components/Header';
import GuestList from './components/GuestList';
import AddGuest from './components/AddGuest';

function App() {
  return (
    <Router>
      <Header />
      <Container className="mt-4">
        <Routes>
          <Route path="/" element={<GuestList />} />
          <Route path="/add" element={<AddGuest />} />
        </Routes>
      </Container>
    </Router>
  );
}

export default App;
