import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Alert, Form, Modal, Container, Row, Col, Pagination, InputGroup, FormControl, Card } from 'react-bootstrap';

const apiUrl = process.env.REACT_APP_API_URL;
const GUESTS_PER_PAGE = 10;

function GuestList() {
  const [guests, setGuests] = useState([]);
  const [filteredGuests, setFilteredGuests] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortField, setSortField] = useState('lastName');
  const [sortDirection, setSortDirection] = useState('asc');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  

  //Editing constants
  const [showEditModal, setShowEditModal] = useState(false);
  const [editingGuest, setEditingGuest] = useState(null);
  const [editFirstName, setEditFirstName] = useState('');
  const [editLastName, setEditLastName] = useState('');
  const [editSnapchat, setEditSnapchat] = useState('');
  const [editBrother, setEditBrother] = useState('');
  const [editRushing, setEditRushing] = useState(false);
  const [showGuestList, setShowGuestList] = useState(true);

  // New guest state
  const [newFirstName, setNewFirstName] = useState('');
  const [newLastName, setNewLastName] = useState('');
  const [newSnapchat, setNewSnapchat] = useState('');
  const [newBrother, setNewBrother] = useState('');
  const [newRushing, setNewRushing] = useState(false);

  useEffect(() => {
    fetchGuests();
  }, []);

  useEffect(() => {
    const filtered = guests.filter(guest => 
      guest.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      guest.lastName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredGuests(filtered);
    setCurrentPage(1);
  }, [searchTerm, guests]);

  const fetchGuests = async () => {
    try {
      const response = await axios.get(`${apiUrl}/guests`);
      setGuests(response.data);
      setFilteredGuests(response.data);
    } catch (err) {
      setError('Failed to fetch guests');
    }
  };

  const handleAddGuest = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${apiUrl}/guests`, {
        firstName: newFirstName,
        lastName: newLastName,
        snapchat: newSnapchat,
        brother: newBrother,
        rushing: newRushing
      });
      fetchGuests();
      setNewFirstName('');
      setNewLastName('');
      setNewSnapchat('');
      setNewBrother('');
      setNewRushing(false);
      setSuccess('Guest added successfully');
      setTimeout(() => setSuccess(''), 3000);
    } catch (err) {
      setError('Failed to add guest');
    }
  };

  const deleteGuest = async (id) => {
    try {
      await axios.delete(`${apiUrl}/guests/${id}`);
      fetchGuests();
      setSuccess('Guest deleted successfully');
      setTimeout(() => setSuccess(''), 3000);
    } catch (err) {
      setError('Failed to delete guest');
    }
  };

  const openEditModal = (guest) => {
    setEditingGuest(guest);
    setEditFirstName(guest.firstName);
    setEditLastName(guest.lastName);
    setEditSnapchat(guest.snapchat);
    setEditBrother(guest.brother);
    setEditRushing(guest.rushing);
    setShowEditModal(true);
  };


  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(`${apiUrl}/guests/${editingGuest._id}`, {
        firstName: editFirstName,
        lastName: editLastName,
        snapchat: editSnapchat,
        brother: editBrother,
        rushing: editRushing
      });
      
      const updatedGuest = response.data;
      setGuests(guests.map(guest => guest._id === updatedGuest._id ? updatedGuest : guest));
      setShowEditModal(false);
      setSuccess('Guest updated successfully');
      setTimeout(() => setSuccess(''), 3000);
    } catch (err) {
      setError('Failed to update guest');
    }
  };

  
  const sortGuests = (field) => {
    const direction = field === sortField && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortField(field);
    setSortDirection(direction);
    
    const sorted = [...filteredGuests].sort((a, b) => {
      if (a[field] < b[field]) return direction === 'asc' ? -1 : 1;
      if (a[field] > b[field]) return direction === 'asc' ? 1 : -1;
      return 0;
    });
    
    setFilteredGuests(sorted);
  };

  const indexOfLastGuest = currentPage * GUESTS_PER_PAGE;
  const indexOfFirstGuest = indexOfLastGuest - GUESTS_PER_PAGE;
  const currentGuests = filteredGuests.slice(indexOfFirstGuest, indexOfLastGuest);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <Container className="bg-dark">
      <h2 className="mb-4 text-light">Guest Management</h2>
      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">{success}</Alert>}
      
      <Card className="notion-card mb-4">
        <Card.Header>
          <h3>Add New Guest</h3>
        </Card.Header>
        <Card.Body>
      <Form onSubmit={handleAddGuest}>
        <Row>
          <Col md={6}>
            <Form.Group>
              <Form.Label>First Name</Form.Label>
              <Form.Control 
                type="text" 
                value={newFirstName} 
                onChange={(e) => setNewFirstName(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Last Name</Form.Label>
              <Form.Control 
                type="text" 
                value={newLastName} 
                onChange={(e) => setNewLastName(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Snapchat</Form.Label>
              <Form.Control 
                type="text" 
                value={newSnapchat} 
                onChange={(e) => setNewSnapchat(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Brother (Name)</Form.Label>
              <Form.Control 
                type="text" 
                value={newBrother} 
                onChange={(e) => setNewBrother(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs={12} sm={6}>
            <Form.Group className="mb-0">
              <Form.Check 
                type="checkbox"
                label="Rushing"
                checked={newRushing}
                onChange={(e) => setNewRushing(e.target.checked)}
              />
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} className="text-sm-right mt-3 mt-sm-0">
            <Button variant="primary" type="submit">Add Guest</Button>
          </Col>
        </Row>
      </Form>
        </Card.Body>
      </Card>

      <Card className="notion-card">
        <Card.Header>
          <div className="d-flex flex-column align-items-start">
            <h3 className="mb-2">Guest List</h3>
            <Button 
              variant="outline-primary"
              onClick={() => setShowGuestList(!showGuestList)}
              className="mb-2"
              size="sm"
            >
              {showGuestList ? 'Hide' : 'Show'} Guest List
            </Button>
          </div>
        </Card.Header>
        {showGuestList && (
          <Card.Body>
            <InputGroup className="mb-3">
              <FormControl
                placeholder="Search guests..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </InputGroup>
           <div className="table-responsive">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th onClick={() => sortGuests('lastName')}>Name {sortField === 'lastName' && (sortDirection === 'asc' ? '▲' : '▼')}</th>
                  <th>Snapchat</th>
                  <th>Brother</th>
                  <th>Rushing</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentGuests.map(guest => (
                  <tr key={guest._id}>
                    <td>{`${guest.lastName}, ${guest.firstName}`}</td>
                    <td>{guest.snapchat}</td>
                    <td>{guest.brother}</td>
                    <td>{guest.rushing ? 'Yes' : 'No'}</td>
                    <td>
                      <Button variant="primary" size="sm" className="mr-2" onClick={() => openEditModal(guest)}>
                        Edit
                      </Button>
                      <Button variant="danger" size="sm" onClick={() => deleteGuest(guest._id)}>
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
           </div>
            <Pagination>
              {[...Array(Math.ceil(filteredGuests.length / GUESTS_PER_PAGE)).keys()].map(number => (
                <Pagination.Item key={number + 1} active={number + 1 === currentPage} onClick={() => paginate(number + 1)}>
                  {number + 1}
                </Pagination.Item>
              ))}
            </Pagination>
          </Card.Body>
        )}
      </Card>

      <Modal 
        show={showEditModal} 
        onHide={() => setShowEditModal(false)}
        className="dark-modal"
      >
        <Modal.Header closeButton className="bg-dark text-light border-secondary">
          <Modal.Title>Edit Guest</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-dark text-light">
          <Form onSubmit={handleEditSubmit}>
            <Form.Group>
              <Form.Label>First Name</Form.Label>
              <Form.Control 
                type="text" 
                value={editFirstName} 
                onChange={(e) => setEditFirstName(e.target.value)}
                required
                className="bg-secondary text-light"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Last Name</Form.Label>
              <Form.Control 
                type="text" 
                value={editLastName} 
                onChange={(e) => setEditLastName(e.target.value)}
                required
                className="bg-secondary text-light"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Snapchat</Form.Label>
              <Form.Control 
                type="text" 
                value={editSnapchat} 
                onChange={(e) => setEditSnapchat(e.target.value)}
                required
                className="bg-secondary text-light"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Brother (Name)</Form.Label>
              <Form.Control 
                type="text" 
                value={editBrother} 
                onChange={(e) => setEditBrother(e.target.value)}
                required
                className="bg-secondary text-light"
              />
            </Form.Group>
            <Form.Group>
              <Form.Check 
                type="checkbox"
                label="Rushing"
                checked={editRushing}
                onChange={(e) => setEditRushing(e.target.checked)}
                className="text-light"
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
}

export default GuestList;
