import React from 'react';
import { Navbar, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Header.css'; // Import your CSS file
import acaciaBanner from './acacia-banner.webp'; // Adjust the path according to your structure

function Header() {
  return (
    <Navbar bg="dark" expand="lg">
      <Container className="justify-content-center"> {/* Centering the content */}
        <Navbar.Brand as={Link} to="/" className="text-center w-100">
          <img
            src={acaciaBanner}
            alt="Acacia Banner" // Provide an alt text for accessibility
            className="banner-img"
          />
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
}

export default Header;